<div style="height: calc(100% - 70px)">
  @if (apiLoaded && order) {
    <google-map #map="googleMap"
      [center]="latestLocationCoordinates"
      [options]="mapOptions"
      height="100%"
      width="100%"
      (zoomChanged)="onZoomChange()"
      >
      @if (origin?.coordinates) {
        <map-advanced-marker #originLocationMarker="mapAdvancedMarker"
          [title]="origin?.type"
          [position]="origin?.coordinates"
          [content]="origin?.pin.element"
          (mapClick)="openStopLocationInfo(originLocationMarker, origin)"
          />
      }
      @if (destination?.coordinates) {
        <map-advanced-marker #destinationLocationMarker="mapAdvancedMarker"
          [title]="destination?.type"
          [position]="destination?.coordinates"
          [content]="destination?.pin.element"
          (mapClick)="openStopLocationInfo(destinationLocationMarker, destination)"
          />
      }
      @if (latestLocationCoordinates) {
        <map-advanced-marker #latestLocationMarker="mapAdvancedMarker"
          title="Last reported location"
          [position]="latestLocationCoordinates"
          (mapClick)="openLatestLocationInfo(latestLocationMarker)"
          [content]="latestLocationPin.element"
          />
      }
      <map-info-window #locationInfoWindow="mapInfoWindow" />
      @if (uncertaintyCircleSizeInPixels > 5) {
        <map-circle
          [center]="latestLocationCoordinates"
          [radius]="uncertaintyRadiusMeters"
          [options]="uncertaintyCircleOptions"
          />
      }
    </google-map>
  }
</div>